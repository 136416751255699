import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { submitSuccessAction, submitFailedAction, initSuccessAction } from './actions'
import { SUBMIT, INIT } from './constants'

const init = (action$) => action$.ofType(INIT).mergeMap(() => Observable.of(initSuccessAction()))

const submit = (action$) =>
  action$.ofType(SUBMIT).exhaustMap(({ phone, deedId }) =>
    UserApi.update({ phone })
      .mergeMap((action) => [action, replace(`/deeds/${deedId}/confirm`), submitSuccessAction()])
      .catch((e) => {
        if (e.responseJson) {
          return [
            submitFailedAction(e.responseJson),
            showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
          ]
        }
        return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
      })
  )

export default [init, submit]
