import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { AlignedImage } from 'src/retired/elements'
import { Body2, H4 } from 'src/retired/shared/Typography'
import { images, icons } from 'src/theme'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import truthy from 'src/utils/truthy'
import { WrappedQuery } from 'src/generated/graphql'
import { getHours } from 'src/utils/convertDuration'

import { Profileception } from './Profileception'

const IMAGE_ASPECT_RATIO = 9 / 16
const MAX_WIDTH = 300

const UnwrappedItem = ({ title, value }: { title: string; value?: number }) => {
  const { colors, metrics } = useDeedTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flex: `0 1 calc(50% - ${metrics.isSmall ? 8 : 16}px)`,
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Body2 style={{ color: colors.white, fontSize: 11 }}>{title}</Body2>
      <H4 style={{ color: colors.white, fontWeight: '700', fontSize: 24 }}>{value}</H4>
    </Box>
  )
}
export const UnwrappedScreen = ({
  imageRef,
  unwrappedData,
  year,
}: {
  imageRef: React.RefObject<HTMLDivElement>
  unwrappedData: WrappedQuery['Wrapped']
  year: number
}) => {
  const { t } = useTranslation('unwrapped')
  const { metrics, colors } = useDeedTheme()
  const user = useSelector(selectCurrentUser)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (imageRef.current) {
      setWidth(!metrics.isSmall && imageRef.current.offsetWidth > MAX_WIDTH ? MAX_WIDTH : imageRef.current.offsetWidth)
    }
    const handleResize = () => setWidth(imageRef.current?.offsetWidth || 0)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [imageRef, metrics.isSmall])

  const {
    donatedCount,
    volunteerTimeMinutes,
    activitiesCount,
    awardedBadgesCount,
    nonprofitsCount,
    campaignsCount,
    awardedBadges,
  } = unwrappedData ?? {}
  const unwrappedItems: {
    key: string
    title: string
    value: number
  }[] = [
    donatedCount
      ? {
          key: 'donationsMade',
          title: t`donationsMade`,
          value: donatedCount,
        }
      : null,
    volunteerTimeMinutes
      ? {
          key: 'hoursVolunteered',
          title: t`hoursVolunteered`,
          value: getHours(volunteerTimeMinutes),
        }
      : null,
    nonprofitsCount
      ? {
          key: 'organizationsImpacted',
          title: t`organizationsImpacted`,
          value: nonprofitsCount,
        }
      : null,
    awardedBadgesCount
      ? {
          key: 'badgesEarned',
          title: t`badgesEarned`,
          value: awardedBadgesCount,
        }
      : null,
    activitiesCount
      ? {
          key: 'actionsCompleted',
          title: t`actionsCompleted`,
          value: activitiesCount,
        }
      : null,
    campaignsCount
      ? {
          key: 'campaignsJoined',
          title: t`campaignsJoined`,
          value: campaignsCount,
        }
      : null,
  ]
    .filter(truthy)
    .slice(0, 4)

  const latestAwardedBadge = awardedBadges?.[0]?.imageURL

  return (
    <Box
      ref={imageRef}
      sx={{
        maxWidth: metrics.isSmall ? undefined : MAX_WIDTH,
        width: '100%',
        height: width / IMAGE_ASPECT_RATIO,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: colors.primary,
        backgroundImage: `url('${icons.unwrappedBackground}')`,
        backgroundSize: `100%`,
        backgroundRepeat: 'no-repeat',
        p: metrics.isSmall ? 2 : 3,
        gap: 3,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {latestAwardedBadge && (
        <AlignedImage
          source={{ uri: latestAwardedBadge }}
          width={50}
          height={50}
          alignment="center"
          style={{
            transform: [{ rotate: '-35deg' }],
            position: 'absolute',
            top: metrics.isSmall ? 80 : 100,
            left: '25%',
            zIndex: 0,
          }}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 3, pb: 3 }}>
        <Body2 style={{ fontWeight: 500, color: colors.white, fontSize: undefined, zIndex: 1 }}>
          {t('youDidGood', { name: user?.fullName?.first || user?.name, year }).toUpperCase()}
        </Body2>
        <AlignedImage source={{ uri: images.logoInlineWhite }} width={70} height={28} alignment="center" style={{}} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', zIndex: 1 }}>
        <Profileception />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexWrap: 'wrap',
            gap: metrics.isSmall ? 1 : 2,
            justifyContent: 'space-between',
          }}
        >
          {unwrappedItems.map(({ key, title, value }) =>
            value > 0 ? <UnwrappedItem key={key} title={title} value={value} /> : null
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', zIndex: 1 }}>
        <Body2 style={{ fontWeight: 700, color: colors.white }}>
          DEED{user?.organization?.name ? ` X ${user?.organization?.name?.toUpperCase()}` : ''}
        </Body2>
        <Body2 style={{ fontWeight: 500, color: colors.white }}>{t('title', { year }).toUpperCase()}</Body2>
      </Box>
    </Box>
  )
}
