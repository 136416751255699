import i18n from 'i18next'
import { ActionsObservable } from 'redux-observable'
import { Observable } from 'rxjs'

import { showErrorAction, showMessageAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'
import DeedsApi from 'src/entities/deed/api'
import truthy from 'src/utils/truthy'

import {
  clearAction,
  InviteAttendeesAction,
  inviteAttendeesFailedAction,
  inviteAttendeesSuccessAction,
  SearchAction,
  searchFailedAction,
  searchSuccessAction,
} from './actions'
import { INVITE_ATTENDEES, SEARCH } from './constants'

const search = (action$: ActionsObservable<SearchAction>) =>
  action$.ofType(SEARCH).switchMap(({ term }: { term: string }) => {
    if (term.length < 2) {
      return []
    }
    return UserApi.search(term)
      .takeUntil(action$.ofType(SEARCH))
      .mergeMap((results) => [searchSuccessAction(results)])
      .catch((e) => Observable.of(searchFailedAction(e), showErrorAction(i18n.t('deedScreen:searchFailed'))))
  })

const inviteAttendees = (action$: ActionsObservable<InviteAttendeesAction>) =>
  action$.ofType(INVITE_ATTENDEES).exhaustMap(({ deedId, invites, resetFormCallback }) => {
    const actions = []

    if (invites.size > 0) {
      const inviteArray = Array.from(invites.values())
      const usersIds = inviteArray.filter((invite) => !invite.email).map((user) => user.id)
      const emails = inviteArray.filter((invite) => invite.email)
      actions.push(DeedsApi.invite(deedId, usersIds, emails))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) =>
        [
          ...resultingActions,
          inviteAttendeesSuccessAction(),
          resetFormCallback?.(),
          showMessageAction(i18n.t('deedScreen:submitSuccess')),
          clearAction(),
        ].filter(truthy)
      )
      .catch((e) => Observable.of(inviteAttendeesFailedAction(e), showErrorAction(i18n.t('deedScreen:submitError'))))
  })

export default [search, inviteAttendees]
