import React from 'react'
import PropTypes from 'prop-types'

import Image from 'src/retired/elements/Image'

const AlignedImage = ({ alignment = 'center', width, height, style, ...props }) => (
  <Image
    backgroundPosition={`center ${alignment}`}
    backgroundSize={props.style?.backgroundSize ?? 'contain'}
    backgroundRepeat="no-repeat"
    style={[
      style,
      {
        width,
        height,
      },
    ]}
    {...props}
  />
)
AlignedImage.propTypes = {
  alignment: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.any,
}

export default AlignedImage
