import addFriend from '../assets/icons/new/add-friend.svg'
import addToCalendarBlack from '../assets/icons/add-to-calendar-black.svg'
import arrowLeft from '../assets/icons/arrow-left.svg'
import arrowLeftNew from '../assets/icons/new/arrow-left.svg'
import arrowLeftWhite from '../assets/icons/arrow-left-white.svg'
import arrowRight from '../assets/icons/new/arrow-right.svg'
import arrowRightBlack from '../assets/icons/arrow-right-black.svg'
import arrowRightGray from '../assets/icons/arrow-right-gray.svg'
import arrowRightWhite from '../assets/icons/arrow-right-white.svg'
import arrowCircleRightBlack from '../assets/icons/arrow-circle-right-black.svg'
import arrowCircleRightPink from '../assets/icons/arrow-circle-right-pink.svg'
import arrowCircleRightFilledPink from '../assets/icons/arrow-circle-right-filled-pink.svg'
import arrowCircleRightWhite from '../assets/icons/arrow-circle-right-white.svg'
import arrowDown from '../assets/icons/new/arrow-down.svg'
import arrowDownBlack from '../assets/icons/arrow-down-black.svg'
import causesWhite from '../assets/icons/causes-white.svg'
import causesBlack from '../assets/icons/causes-black.svg'
import checkedIn from '../assets/icons/checked-in.svg'
import checkedInMini from '../assets/icons/checked-in-mini.svg'
import checkmark from '../assets/icons/checkmark.svg'
import cheer from '../assets/icons/cheer.svg'
import cheered from '../assets/icons/cheered.svg'
import circleWithLock from '../assets/icons/circle-with-lock.svg'
import city from '../assets/icons/city.svg'
import clap from '../assets/icons/clap.svg'
import cloud from '../assets/icons/cloud.svg'
import close from '../assets/icons/close.svg'
import closeBlack from '../assets/icons/close-black.svg'
import closeNew from '../assets/icons/new/close.svg'
import closeCircle from '../assets/icons/new/close-circle.svg'
import closeThick from '../assets/icons/new/close-thick.svg'
import colon from '../assets/icons/new/colon.svg'
import crossWhite from '../assets/icons/cross-white.svg'
import donate from '../assets/icons/new/donate.svg'
import dateRange from '../assets/icons/date-range.svg'
import disabledHourGlass from '../assets/icons/disabled-hourglass.svg'
import downloadOutlined from '../assets/icons/download-outlined.svg'
import email from '../assets/icons/new/email.svg'
import exclamationMarkCircleWhite from '../assets/icons/exclamation-mark-circle-white.svg'
import explore from '../assets/icons/new/explore.svg'
import eyes from '../assets/icons/eyes.svg'
import facebookBlack from '../assets/icons/facebook-black.svg'
import facebookNew from '../assets/icons/new/facebook.svg'
import facebookWhite from '../assets/icons/facebook-white.svg'
import filter from '../assets/icons/new/filter.svg'
import filters from '../assets/filters.svg'
import filterActive from '../assets/icons/new/filter-active.svg'
import filterBlack from '../assets/icons/filter-black.svg'
import filterPink from '../assets/icons/filter-pink.svg'
import filterWhite from '../assets/icons/filter-white.svg'
import gear from '../assets/icons/gear.svg'
import globe from '../assets/icons/globe.svg'
import heart from '../assets/icons/heart.svg'
import heartHand from '../assets/icons/heart-hand.svg'
import heartMessage from '../assets/icons/heart-message.svg'
import heartPersonMessage from '../assets/icons/heart-person-message.svg'
import heartWhiteFilled from '../assets/icons/heart-white-filled.svg'
import heartWhiteOutlined from '../assets/icons/heart-white-outlined.svg'
import homeFilled from '../assets/icons/new/home-filled.svg'
import instagramBlack from '../assets/icons/instagram-black.svg'
import locationPin from '../assets/icons/location-pin.svg'
import link from '../assets/icons/new/link.svg'
import linkedIn from '../assets/icons/new/linked-in.svg'
import plus from '../assets/icons/new/plus.svg'
import markerDarkBlue from '../assets/icons/marker-dark-blue.svg'
import notificationBlack from '../assets/icons/notification-black.svg'
import notificationNew from '../assets/icons/new/notification.svg'
import notificationWhite from '../assets/icons/notification-white.svg'
import noActionsIllustration from '../assets/no-actions.svg'
import noResultsIllustration from '../assets/no-results.svg'
import noResultsFilterType from '../assets/no-filter-type.svg'
import noNonprofitsIllustration from '../assets/no-nonprofits.svg'
import highfiveIllustration from '../assets/highfive-illustration.svg'
import heartOnHandIllustration from '../assets/heart-on-hand-illustration.svg'
import womanStarIllustration from '../assets/woman-star-illustration.svg'
import receiptLongOutlined from '../assets/icons/receipt-long-outlined.svg'
import refresh from '../assets/icons/new/refresh.svg'
import rejectCircleBlack from '../assets/icons/reject-circle-black.svg'
import search from '../assets/icons/search.svg'
import searchNew from '../assets/icons/new/search.svg'
import searchPink from '../assets/icons/search-pink.svg'
import shareWhite from '../assets/icons/share-white.svg'
import starFilledPink from '../assets/icons/star-filled-pink.svg'
import starOutlinePink from '../assets/icons/star-outline-pink.svg'
import tick from '../assets/icons/new/tick.svg'
import tickWhite from '../assets/icons/tick-white.svg'
import tickCircleFilledPink from '../assets/icons/tick-circle-filled-pink.svg'
import tickCircleFilledGreen from '../assets/icons/tick-circle-filled-green.svg'
import tickCirclePink from '../assets/icons/tick-circle-pink.svg'
import tickCircleWhite from '../assets/icons/tick-circle-white.svg'
import ticket from '../assets/icons/new/ticket.svg'
import triangleLeft from '../assets/icons/new/triangle-left.svg'
import triangleRight from '../assets/icons/new/triangle-right.svg'
import triangleUp from '../assets/icons/new/triangle-up.svg'
import triangleDown from '../assets/icons/new/triangle-down.svg'
import triangleDownFilled from '../assets/icons/triangle-down-filled.svg'
import thinkingHumanIllustration from '../assets/icons/thinking-human-illustration.svg'
import twitter from '../assets/icons/new/twitter.svg'
import peaceFingers from '../assets/icons/peace-fingers.svg'
import personRunning from '../assets/icons/person-running.svg'
import plusCircleBlack from '../assets/icons/plus-circle-black.svg'
import plusCircleGray from '../assets/icons/plus-circle-gray.svg'
import plusCirclePink from '../assets/icons/plus-circle-pink.svg'
import menuMore from '../assets/icons/new/menu-more.svg'
import profilePinkFilled from '../assets/icons/profile-pink-filled.svg'
import profilesPinkFilled from '../assets/icons/profiles-pink-filled.svg'
import volunteer from '../assets/icons/new/volunteer.svg'
import volunteerPerson from '../assets/icons/new/volunteer-person.svg'
import infoCircleBlack from '../assets/icons/new/info-circle-black.svg'
import circleWhiteCheck from '../assets/icons/new/circle-white-check.svg'
import questionCircleBlack from '../assets/icons/new/question-circle-black.svg'
import infoSquareDeclined from '../assets/icons/info-square-declined.svg'
import tickSquareSuccess from '../assets/icons/tick-square-success.svg'
import timeCirclePending from '../assets/icons/time-circle-pending.svg'
import timeCirclePendingWhite from '../assets/icons/time-circle-pending-white.svg'
import trees from '../assets/icons/trees.svg'
import externalResource from '../assets/icons/external-resource.svg'
import trashWhite from '../assets/icons/trash-white.svg'
import infoSquareGray from '../assets/icons/info-square-gray.svg'
import eyeIcon from '../assets/icons/eyeIcon.svg'
import edit from '../assets/icons/edit.svg'
import cancel from '../assets/icons/cancel-button.svg'
import sadCloud from '../assets/icons/sad-cloud.svg'
import smile from '../assets/icons/new/smile.svg'
import infoCircleOutlinedBlack from '../assets/icons/info-circle-outlined-black.svg'
import infoCircleOutlinedWhite from '../assets/icons/info-circle-outlined-white.svg'
import infoCircleOutlinedRed from '../assets/icons/info-circle-outlined-red.svg'
import communityJoined from '../assets/icons/community-joined.svg'
import searchIllustration from '../assets/search-illustration.svg'
import pledge from '../assets/pledge.svg'
import pledgeBlock from '../assets/pledgeBlock.svg'
import personThinking from '../assets/person-thinking.svg'
import personBox from '../assets/person-box.svg'
import personStar from '../assets/person-star.svg'
import personFistUp from '../assets/person-fist-up.svg'
import editRoadOutlined from '../assets/icons/edit-road-outlined.svg'
import starBadge from '../assets/star-badge.svg'
import timeCircle from '../assets/icons/time-circle.svg'
// quickActions icons
import autoFixHighOutlined from '../assets/auto-fix-high-outlined.svg'
import autoGraphOutlined from '../assets/auto-graph-outlined.svg'
import hourglassEmptyOutlined from '../assets/hourglass-empty-outlined.svg'
import localActivityOutlined from '../assets/local-activity-outlined.svg'
import savingsOutlined from '../assets/savings-outlined.svg'
import workspacePremiumOutlined from '../assets/workspace-premium-outlined.svg'
import unwrappedLogo from '../assets/unwrapped_logo.svg'
// category icons
import advocacy from '../assets/icons/categories-new/advocacy.svg'
import animals from '../assets/icons/categories-new/animals.svg'
import arts from '../assets/icons/categories-new/arts.svg'
import children from '../assets/icons/categories-new/children.svg'
import disabilities from '../assets/icons/categories-new/disabilities.svg'
import disasterRecovery from '../assets/icons/categories-new/disaster-recovery.svg'
import education from '../assets/icons/categories-new/education.svg'
import employment from '../assets/icons/categories-new/employment.svg'
import environment from '../assets/icons/categories-new/environment.svg'
import foodAccess from '../assets/icons/categories-new/food-access.svg'
import health from '../assets/icons/categories-new/health.svg'
import homelessness from '../assets/icons/categories-new/homelessness.svg'
import lgbtq from '../assets/icons/categories-new/lgbtq.svg'
import mentalHealth from '../assets/icons/categories-new/mental-health.svg'
import mentoring from '../assets/icons/categories-new/mentoring.svg'
import politics from '../assets/icons/categories-new/politics.svg'
import seniors from '../assets/icons/categories-new/seniors.svg'
import smileFallback from '../assets/icons/categories-new/smile-fallback.svg'
import sportsRecreation from '../assets/icons/categories-new/sports-recreation.svg'
import sustainability from '../assets/icons/categories-new/sustainability.svg'
import technology from '../assets/icons/categories-new/technology.svg'
import veterans from '../assets/icons/categories-new/veterans.svg'
import women from '../assets/icons/categories-new/women.svg'
import youth from '../assets/icons/categories-new/youth.svg'
import warn from '../assets/icons/warn.svg'
import megaphone from '../assets/icons/megaphone.svg'
import actions from '../assets/icons/actions.svg'
import notepad from '../assets/icons/notepad.svg'
// sdg icons
import affordableEnergy from '../assets/icons/sdgs/affordableEnergy.svg'
import cleanWater from '../assets/icons/sdgs/cleanWater.svg'
import climateAction from '../assets/icons/sdgs/climateAction.svg'
import decentWork from '../assets/icons/sdgs/decentWork.svg'
import genderEquality from '../assets/icons/sdgs/genderEquality.svg'
import goodHealth from '../assets/icons/sdgs/goodHealth.svg'
import industry from '../assets/icons/sdgs/industry.svg'
import lifeBelowWater from '../assets/icons/sdgs/lifeBelowWater.svg'
import lifeOnLand from '../assets/icons/sdgs/lifeOnLand.svg'
import noPoverty from '../assets/icons/sdgs/noPoverty.svg'
import partnershipForGoals from '../assets/icons/sdgs/partnershipForGoals.svg'
import peaceJustice from '../assets/icons/sdgs/peaceJustice.svg'
import qualityEducation from '../assets/icons/sdgs/qualityEducation.svg'
import reduceInequalities from '../assets/icons/sdgs/reduceInequalities.svg'
import responsibleConsumption from '../assets/icons/sdgs/responsibleConsumption.svg'
import sustainableCities from '../assets/icons/sdgs/sustainableCities.svg'
import zeroHunger from '../assets/icons/sdgs/zeroHunger.svg'
import thankYouModal from '../assets/images/thank-you-modal.svg'
import unwrappedBackground from '../assets/unwrapped_background.svg'

export default {
  addFriend,
  addToCalendarBlack,
  arrowLeft,
  arrowLeftNew,
  arrowLeftWhite,
  arrowRight,
  arrowRightBlack,
  arrowRightGray,
  arrowRightWhite,
  arrowCircleRightBlack,
  arrowCircleRightPink,
  arrowCircleRightFilledPink,
  arrowCircleRightWhite,
  arrowDown,
  arrowDownBlack,
  causesWhite,
  causesBlack,
  checkedIn,
  checkedInMini,
  checkmark,
  cheer,
  cheered,
  circleWithLock,
  city,
  clap,
  cloud,
  close,
  closeBlack,
  closeNew,
  closeCircle,
  closeThick,
  colon,
  crossWhite,
  donate,
  downloadOutlined,
  email,
  exclamationMarkCircleWhite,
  explore,
  eyes,
  facebookBlack,
  facebookNew,
  facebookWhite,
  filter,
  filters,
  filterActive,
  filterBlack,
  filterPink,
  filterWhite,
  gear,
  globe,
  heart,
  heartHand,
  heartMessage,
  heartPersonMessage,
  heartWhiteFilled,
  heartWhiteOutlined,
  homeFilled,
  instagramBlack,
  link,
  linkedIn,
  locationPin,
  plus,
  markerDarkBlue,
  notificationBlack,
  notificationNew,
  notificationWhite,
  receiptLongOutlined,
  refresh,
  rejectCircleBlack,
  search,
  searchNew,
  searchPink,
  searchIllustration,
  noActionsIllustration,
  noResultsIllustration,
  noNonprofitsIllustration,
  highfiveIllustration,
  heartOnHandIllustration,
  womanStarIllustration,
  noResultsFilterType,
  shareWhite,
  starFilledPink,
  starOutlinePink,
  tick,
  tickWhite,
  tickCircleFilledPink,
  tickCircleFilledGreen,
  tickCirclePink,
  tickCircleWhite,
  triangleDown,
  triangleLeft,
  triangleRight,
  triangleUp,
  triangleDownFilled,
  thinkingHumanIllustration,
  trees,
  twitter,
  peaceFingers,
  personRunning,
  plusCircleBlack,
  plusCircleGray,
  plusCirclePink,
  menuMore,
  profilePinkFilled,
  profilesPinkFilled,
  volunteer,
  volunteerPerson,
  infoCircleBlack,
  circleWhiteCheck,
  questionCircleBlack,
  infoSquareDeclined,
  tickSquareSuccess,
  ticket,
  timeCirclePending,
  timeCirclePendingWhite,
  externalResource,
  trashWhite,
  infoSquareGray,
  eyeIcon,
  edit,
  cancel,
  sadCloud,
  smile,
  infoCircleOutlinedBlack,
  infoCircleOutlinedWhite,
  infoCircleOutlinedRed,
  communityJoined,
  pledge,
  pledgeBlock,
  personThinking,
  personBox,
  personStar,
  personFistUp,
  editRoadOutlined,
  megaphone,
  actions,
  notepad,
  starBadge,
  timeCircle,
  // quickActions icons
  autoFixHighOutlined,
  autoGraphOutlined,
  hourglassEmptyOutlined,
  localActivityOutlined,
  savingsOutlined,
  workspacePremiumOutlined,
  unwrappedLogo,
  // category icons
  advocacy,
  animals,
  arts,
  children,
  disabilities,
  disasterRecovery,
  education,
  employment,
  environment,
  foodAccess,
  health,
  homelessness,
  lgbtq,
  mentalHealth,
  mentoring,
  politics,
  seniors,
  smileFallback,
  sportsRecreation,
  sustainability,
  technology,
  veterans,
  women,
  youth,
  dateRange,
  disabledHourGlass,
  warn,
  // sdg icons
  affordableEnergy,
  cleanWater,
  climateAction,
  decentWork,
  genderEquality,
  goodHealth,
  industry,
  lifeBelowWater,
  lifeOnLand,
  noPoverty,
  partnershipForGoals,
  peaceJustice,
  qualityEducation,
  reduceInequalities,
  responsibleConsumption,
  sustainableCities,
  zeroHunger,
  thankYouModal,
  unwrappedBackground,
}
