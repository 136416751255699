import * as Sentry from '@sentry/react'
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { useWrappedQuery, WrappedQuery } from 'src/generated/graphql'

interface UseUnwrappedReturn {
  showUnwrappedQuickAction: boolean
  loading: boolean
  hasUserEnoughData: boolean
  unwrappedData: WrappedQuery['Wrapped']
  isReviewPeriod: boolean
  unwrappedYear: number
  isSocialSharingDisabled: boolean | undefined
}

export const useUnwrapped = (): UseUnwrappedReturn => {
  const currentDate = new Date()
  const isCurrentYearUnwrappedYear = currentDate.getMonth() === 12
  const currentYear = new Date().getFullYear()
  const unwrappedYear = isCurrentYearUnwrappedYear ? currentYear : currentYear - 1

  const user = useSelector(selectCurrentUser)
  const unwrappedEnabled = user?.hasFeature('unwrapped')
  const isSocialSharingDisabled = user?.organization?.settings?.disableSocialSharing

  const isReviewPeriod = useMemo(() => {
    const now = new Date()
    return (
      isAfter(now, startOfDay(new Date(`${unwrappedYear}-12-01`))) &&
      isBefore(now, endOfDay(new Date(`${unwrappedYear + 1}-02-28`)))
    )
  }, [unwrappedYear])

  const periodStart = useMemo(() => startOfDay(new Date(`${unwrappedYear}-01-01`)), [unwrappedYear])
  const periodEnd = useMemo(() => endOfDay(new Date(`${unwrappedYear}-12-31`)), [unwrappedYear])

  const { data, loading } = useWrappedQuery({
    variables: { periodStart, periodEnd },
    skip: !isReviewPeriod || !unwrappedEnabled,
    onError: (error: Error) => {
      Sentry.captureException(error)
    },
  })

  const unwrappedData = data?.Wrapped ?? {}
  const hasUserEnoughData: boolean = unwrappedData?.hasEnoughData ?? false

  return {
    showUnwrappedQuickAction: isReviewPeriod && hasUserEnoughData,
    hasUserEnoughData,
    loading,
    unwrappedData,
    isReviewPeriod,
    unwrappedYear,
    isSocialSharingDisabled,
  }
}
