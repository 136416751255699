import logoGraphicBlack from '../assets/DEED-icon-black.svg'
import logoGraphicWhite from '../assets/DEED-icon-white.svg'
import logoWordmarkBlack from '../assets/DEED-icon-wordmark-black.svg'
import logoWordmarkWhite from '../assets/DEED-icon-wordmark-white.svg'
import wavingLogo from '../assets/waving-logo.svg'
import landing1 from '../assets/landing/landing1.jpg'
import landing2 from '../assets/landing/landing2.jpg'
import landing3 from '../assets/landing/landing3.jpg'
import appStoreLogo from '../assets/app-store-logo.svg'
import playStoreLogo from '../assets/play-store-logo.png'
import headerToggle from '../assets/header-toggle.svg'
import NFGLogo from '../assets/NFGLogo.svg'
import ppLogo from '../assets/ppLogo.svg'
import ggLogo from '../assets/opportunityProviders/gg.svg'
import cfLogo from '../assets/opportunityProviders/cf.svg'
import vmLogo from '../assets/opportunityProviders/vm.svg'
import plLogo from '../assets/opportunityProviders/pl.svg'
import vgLogo from '../assets/opportunityProviders/vg.svg'
import idLogo from '../assets/opportunityProviders/id.svg'
import goLogo from '../assets/opportunityProviders/go.svg'
import puzzlePiece from '../assets/puzzle-piece.svg'
import smileyBlush from '../assets/smiley-blush.png'
import smilingFaceWithHeartEyes from '../assets/smiling-face-with-heart-eyes.png'
import sadCloud from '../assets/sad-cloud.png'
import deedRectangleOrange from '../assets/deed-rectangle-orange.svg'
import deedSadOrange from '../assets/deed-sad-orange.svg'
import closeCirclePurple from '../assets/close-circle-purple.svg'
import userPlaceholder from '../assets/user-placeholder.png'
import nonprofitPlaceholder from '../assets/nonprofit-placeholder.png'
import guestCheckoutImage01 from '../assets/guestcheckout01.png'
import guestCheckoutImage02 from '../assets/guestcheckout02.png'
import guestCheckoutImage03 from '../assets/guestcheckout03.png'
import guestCheckoutImage04 from '../assets/guestcheckout04.png'
import guestCheckoutImage05 from '../assets/guestcheckout05.png'
import guestCheckoutImage06 from '../assets/guestcheckout06.png'
import guestCheckoutImage07 from '../assets/guestcheckout07.png'
import handHeart from '../assets/handheart.svg'
import deedLogoHorizontal from '../assets/deed-logo-horizontal.svg'
import deedLogoHorizontalGray from '../assets/deed-logo-horizontal-gray.svg'
import logoStackedOffWhite from '../assets/logo/stacked/deed-logo-stacked-offwhite.svg'
import logoStackedPrimaryPurple from '../assets/logo/stacked/deed-logo-stacked-primary-purple.svg'
import logoStackedSecondaryPurple from '../assets/logo/stacked/deed-logo-stacked-secondary-purple.svg'
import logoStackedWhite from '../assets/logo/stacked/deed-logo-stacked-white.svg'
import logoStackedBlack from '../assets/logo/stacked/deed-logo-stacked-black.svg'
import logoInlineOffWhite from '../assets/logo/inline/deed-logo-inline-offwhite.svg'
import logoInlinePrimaryPurple from '../assets/logo/inline/deed-logo-inline-primary-purple.svg'
import logoInlineSecondaryPurple from '../assets/logo/inline/deed-logo-inline-secondary-purple.svg'
import logoInlineWhite from '../assets/logo/inline/deed-logo-inline-white.svg'
import logoInlineBlack from '../assets/logo/inline/deed-logo-inline-black.svg'
import communitiesPlaceholder from '../assets/communities-placeholder.svg'
import deedLogoPurple from '../assets/icons/deed-logo-purple.svg'
import deedLogoRed from '../assets/icons/deed-logo-red.svg'
import exclamationCircleGray from '../assets/icons/exclamation-circle-gray.svg'
import pledge from '../assets/pledge.svg'
import poweredByDeed from '../assets/images/powered-by-deed.svg'
import unwrappedLogo from '../assets/unwrapped_logo.svg'

export const providerLogos = {
  NFGLogo,
  ggLogo,
  vmLogo,
  ppLogo,
  plLogo,
  vgLogo,
  cfLogo,
  idLogo,
  goLogo,
}

const images = {
  ...providerLogos,
  logoGraphicBlack,
  logoGraphicWhite,
  logoWordmarkBlack,
  logoWordmarkWhite,
  wavingLogo,
  landing1,
  landing2,
  landing3,
  appStoreLogo,
  playStoreLogo,
  headerToggle,
  smileyBlush,
  smilingFaceWithHeartEyes,
  sadCloud,
  userPlaceholder,
  nonprofitPlaceholder,
  guestCheckoutImage01,
  guestCheckoutImage02,
  guestCheckoutImage03,
  guestCheckoutImage04,
  guestCheckoutImage05,
  guestCheckoutImage06,
  guestCheckoutImage07,
  handHeart,
  deedLogoHorizontal,
  deedLogoHorizontalGray,
  puzzlePiece,
  logoStackedOffWhite,
  logoStackedPrimaryPurple,
  logoStackedSecondaryPurple,
  logoStackedWhite,
  logoStackedBlack,
  logoInlineOffWhite,
  logoInlinePrimaryPurple,
  logoInlineSecondaryPurple,
  logoInlineWhite,
  logoInlineBlack,
  deedRectangleOrange,
  deedSadOrange,
  closeCirclePurple,
  communitiesPlaceholder,
  deedLogoPurple,
  deedLogoRed,
  exclamationCircleGray,
  pledge,
  poweredByDeed,
  unwrappedLogo,
}

export default images

export type ThemeImages = typeof images
