import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { AlignedImage } from 'src/retired/elements'
import { images } from 'src/theme'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const Profileception = () => {
  const { colors } = useDeedTheme()
  const user = useSelector(selectCurrentUser)
  const IMAGE_SIZE = 120
  const IMAGE_PROPORTION = IMAGE_SIZE / 6
  const REPEAT_AMOUNT = 4
  const userPicture = user?.mainPicture?.endsWith?.('profile.jpg') ? null : user?.mainPicture

  return (
    <Box
      sx={{
        position: 'relative',
        height: IMAGE_SIZE,
        width: IMAGE_SIZE,
        mt: 6,
      }}
    >
      {[...Array(REPEAT_AMOUNT)].map((_, index) => (
        <AlignedImage
          key={index}
          source={{ uri: userPicture || images.unwrappedLogo }}
          width={IMAGE_SIZE - index * IMAGE_PROPORTION}
          height={IMAGE_SIZE - index * IMAGE_PROPORTION}
          alignment="center"
          style={{
            backgroundColor: colors.primaryLighter,
            position: 'absolute',
            zIndex: REPEAT_AMOUNT - 1 - index,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: colors.black,
            left: IMAGE_SIZE * 0.4 * index,
            top: -16 * index,
          }}
        />
      ))}
    </Box>
  )
}
