import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'
import { REHYDRATE } from 'redux-persist/constants'

import { TypedMap } from 'src/utils/typed-map'

import { ACCEPT, DECLINE, SHOW_COOKIE_CONSENT } from './constants'

export type CookieConsentState = TypedMap<{
  hasAcceptedCookies: boolean
  hasResponded: boolean
  showCookieConsent: boolean
}>

export const initialState: CookieConsentState = fromJS({
  hasAcceptedCookies: false,
  hasResponded: false,
  showCookieConsent: true,
})

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case REHYDRATE:
      return action.payload.cookieConsent || initialState

    case ACCEPT:
      return state.merge({ hasAcceptedCookies: true, hasResponded: true, showCookieConsent: false })

    case DECLINE:
      return state.merge({ hasAcceptedCookies: false, hasResponded: true, showCookieConsent: false })

    case SHOW_COOKIE_CONSENT:
      return state.merge({ showCookieConsent: true })

    default:
      return state
  }
}
