import { Directory, Filesystem } from '@capacitor/filesystem'
import { Box, Button, CircularProgress } from '@mui/material'
import { toBlob, toPng } from 'html-to-image'
import type { Options } from 'html-to-image/lib/types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useLocation } from 'react-router'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import { useUnwrapped } from 'src/containers/screens/Home/QuickActions/useUnwrapped'
import { Body1 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Platform, Share } from 'src/utils'
import { isDesktop } from 'src/utils/isDesktop'
import * as Sentry from 'src/utils/Sentry'

import { UnwrappedScreen } from './UnwrappedScreen'

export const UnwrappedModal = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory()
  const { search } = useLocation()
  const { t } = useTranslation('unwrapped')
  const { colors, metrics } = useDeedTheme()
  const { loading, unwrappedData, hasUserEnoughData, unwrappedYear: year, isSocialSharingDisabled } = useUnwrapped()

  const imageRef = useRef(null)

  const [isSharing, setIsSharing] = useState(false)

  const hasError = search.includes('outcome=failure')

  if (!hasUserEnoughData && !loading) {
    return <Redirect to="/home" />
  }

  const handleShare = async () => {
    try {
      const fileName = `impact_unwrapped_${year}.png`
      const imageType = 'image/png'
      const options: Options = {
        type: imageType,
        fetchRequestInit: {
          mode: 'cors',
          headers: {
            Origin: window.location.origin,
          },
        },
      }

      const newFile = isDesktop() ? await toPng(imageRef.current!, options) : await toBlob(imageRef.current!, options)
      const data = {
        files: [
          new File([newFile!], fileName, {
            type: imageType,
            lastModified: new Date().getTime(),
          }),
        ],
        title: t('title', { year }),
        text: t(`joinMeNextYear`, { year, nextYear: year + 1 }),
      }

      if (isDesktop()) {
        const link = document.createElement('a')
        link.download = fileName
        link.href = newFile as string
        document.body.appendChild(link)
        link.click()
        setIsSharing(false)
      } else if (Platform.OS !== 'web') {
        // @NOTE-CH: Capacitor share needs a file, so we need to save the image in cache
        // https://capacitorjs.com/docs/apis/share & https://stackoverflow.com/a/76260185
        // @NOTE-CH: I don't know why but reusing the `newFile` variable won't work here
        const b64image = await toPng(imageRef.current!, options)
        // Save file in cache
        await Filesystem.writeFile({
          path: fileName,
          data: b64image,
          directory: Directory.Cache,
        })
        // Get full uri of the saved image
        const imgData = await Filesystem.getUri({
          path: fileName,
          directory: Directory.Cache,
        })
        // Finally, share the image
        await Share.share({
          files: [imgData.uri],
          text: data.text,
          dialogTitle: data.title,
        }).catch((error) => {
          // @NOTE-CH: We only throw if it's not the share canceled error
          if (!['Share canceled'].includes(error?.message)) {
            throw error
          }
        })
      } else if (navigator?.share && navigator?.canShare?.(data)) {
        await navigator.share(data).catch((error) => {
          // @NOTE-CH: We ignore `AbortError` because it's expected when the user cancels the share dialog
          if (error.name === 'AbortError') {
            Sentry.captureMessage('navigator.share aborted', { extra: { error } })
          } else {
            throw error
          }
        })
      }
      history.replace('/home?wrapped=true&outcome=success')
    } catch (error) {
      Sentry.captureException(error)
      history.replace('/home?wrapped=true&outcome=failure')
    } finally {
      setIsSharing(false)
    }
  }

  return (
    <ModalWithCloseButton
      visible
      onClose={onClose}
      title={t('title', { year })}
      contentStyle={{
        width: metrics.isSmall ? '100%' : 'auto',
        height: 'auto',
        padding: 20,
      }}
      titleStyle={{ fontSize: metrics.isSmall ? 16 : 20 }}
    >
      <>
        <Box
          sx={{
            height: loading || hasError ? 300 : 'auto',
            width: metrics.isSmall ? 'auto' : 600,
            backgroundColor: colors.gray02,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mx: '-20px',
            mt: '20px',
            py: metrics.isSmall ? 0 : 2,
            px: hasError ? 2 : 0,
          }}
        >
          {loading ? (
            <CircularProgress size={32} />
          ) : hasError ? (
            <Body1 style={{ textAlign: 'center' }}>{t`shareErrorMessage`}</Body1>
          ) : (
            <UnwrappedScreen unwrappedData={unwrappedData} year={year} imageRef={imageRef} />
          )}
        </Box>
        {isSharing && !hasError && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: `calc(100% - ${metrics.isSmall ? 132 : 143}px)`,
              borderBottomLeftRadius: 0,
              left: 0,
              top: metrics.isSmall ? 64 : 68,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 5,
              backgroundColor: colors.gray02,
              opacity: 0.5,
            }}
          >
            <CircularProgress size={32} />
          </Box>
        )}
        {loading || isSocialSharingDisabled ? null : hasError ? (
          <Button
            variant="outlined"
            sx={{
              mt: 2,
              width: metrics.isSmall ? '100%' : 'fit-content',
              alignSelf: 'center',
              color: colors.black,
              borderColor: colors.black,
            }}
            onClick={() => {
              onClose()
              setIsSharing(false)
            }}
          >
            {t`common:close`}
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              mt: 2,
              width: metrics.isSmall ? '100%' : 'fit-content',
              alignSelf: 'center',
              color: colors.black,
              borderColor: colors.black,
            }}
            disabled={isSharing}
            onClick={() => {
              setIsSharing(true)
              // Mumbo-jumbo to let the UI to adjust to the sharable sizing before capturing the screenshot
              setTimeout(() => {
                handleShare()
              }, 300)
            }}
          >
            {isDesktop() ? t`common:download` : t`common:share`}
          </Button>
        )}
      </>
    </ModalWithCloseButton>
  )
}
