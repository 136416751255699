import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from 'src/retired/elements'
import { colors } from 'src/theme'

interface UserSearchResultItem {
  _id: string
  id: string
  fullName: { first: string; last: string }
  name: string
  mainPicture: string
  score: number
}

interface UserSearchListProps {
  term: string
  results: UserSearchResultItem[]
  loading: boolean
  error: string | null
  selected: Map<string, UserSearchResultItem>
  onSearch: (value: string) => void
  onClearSearch: () => void
  onSelect: (id: string, user: UserSearchResultItem) => void
}

const UserSearchResult = ({
  user,
  selected,
  searchTerm,
}: {
  user: UserSearchResultItem
  selected: boolean
  searchTerm: string
}) => {
  const first = user?.fullName?.first || ''
  const last = user?.fullName?.last || ''
  const fullName = `${first} ${last}`
  const terms = searchTerm.trim().split(/\s+/).filter(Boolean)

  // Find all matches and their positions
  const matches: Array<{ start: number; end: number }> = []
  terms.forEach((term) => {
    const lowerTerm = term.toLowerCase()
    const lowerName = fullName.toLowerCase()
    let pos = 0
    let foundPos = lowerName.indexOf(lowerTerm, pos)
    while (foundPos !== -1) {
      matches.push({ start: foundPos, end: foundPos + term.length })
      pos = foundPos + 1
      foundPos = lowerName.indexOf(lowerTerm, pos)
    }
  })

  // Sort matches by position and merge overlapping ones
  matches.sort((a, b) => a.start - b.start)
  const mergedMatches: Array<{ start: number; end: number }> = []
  matches.forEach((match) => {
    const lastMatch = mergedMatches[mergedMatches.length - 1]
    if (lastMatch && match.start <= lastMatch.end) {
      lastMatch.end = Math.max(lastMatch.end, match.end)
    } else {
      mergedMatches.push(match)
    }
  })

  // Build the highlighted text
  const parts: React.ReactNode[] = []
  let lastEnd = 0
  mergedMatches.forEach(({ start, end }) => {
    if (start > lastEnd) {
      parts.push(fullName.substring(lastEnd, start))
    }
    parts.push(
      <Text key={start} style={{ fontWeight: 600 }}>
        {fullName.substring(start, end)}
      </Text>
    )
    lastEnd = end
  })
  if (lastEnd < fullName.length) {
    parts.push(fullName.substring(lastEnd))
  }

  return (
    <>
      <Text numberOfLines={1} lineHeight="26px">
        {parts}
      </Text>
      {selected ? (
        <CheckCircleIcon color="success" fontSize="small" />
      ) : (
        <AddCircleOutlineOutlinedIcon fontSize="small" />
      )}
    </>
  )
}

export const UserSearchList: React.FC<UserSearchListProps> = ({
  term: initialTerm,
  results,
  loading,
  error,
  selected,
  onSearch,
  onClearSearch,
  onSelect,
}) => {
  const { t } = useTranslation('deedScreen')
  const [term, setTerm] = useState(initialTerm)

  const debouncedSearch = useMemo(() => debounce(onSearch, 300), [onSearch])

  const handleSearchChange = (value: string) => {
    setTerm(value)
    debouncedSearch(value)
  }

  const handleClearSearch = () => {
    onClearSearch()
    setTerm('')
  }

  useEffect(
    () => () => {
      debouncedSearch.cancel()
    },
    [debouncedSearch]
  )

  return (
    <Box>
      <TextField
        variant="standard"
        label="Search"
        onChange={(e) => handleSearchChange(e.target.value)}
        value={term}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {term && (
                <IconButton onClick={handleClearSearch} edge="end" sx={{ mr: 1 }}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      {loading ? (
        <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={14} />
        </Box>
      ) : term.length > 1 && results.length === 0 ? (
        <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" align="center">
            {error || t`noMatchedFound`}
          </Typography>
        </Box>
      ) : results.length > 0 ? (
        <Box sx={{ overflowY: 'scroll', maxHeight: 300, width: '100%' }}>
          {results.map((user) => (
            <ListItem
              component={ButtonBase}
              key={user.id}
              onClick={() => onSelect(user.id, user)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'space-between',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: colors.gray03,
                },
              }}
            >
              <UserSearchResult user={user} selected={selected.has(user.id)} searchTerm={term} />
            </ListItem>
          ))}
        </Box>
      ) : (
        <Box sx={{ pb: 2 }} />
      )}
    </Box>
  )
}
