/* global __DETECTED_VIEWER_TIME_ZONE__ */

import { LOCATION_CHANGE } from 'connected-react-router'

import config from 'src/config'
import { ADD as ENTITIES_USER_ADD } from 'src/entities/user/constants'
import metricsClient from 'src/metrics/client'
import { baseFontSize } from 'src/metrics/util'

import { trackProfileActions } from './profile'

export const metrics = (store) => (next) => (action) => {
  trackProfileActions(store, action)

  const { configure, pageView, trackReduxAction } = metricsClient

  configure([
    {
      traits: {
        // eslint-disable-next-line camelcase
        base_font_size: baseFontSize,
        environment: config.environment,
      },
      context: {
        timezone: __DETECTED_VIEWER_TIME_ZONE__,
      },
      properties: {
        environment: config.environment,
      },
    },
  ])

  switch (action.type) {
    case LOCATION_CHANGE: {
      // @NOTE-CH: let's not send the location state payload
      // eslint-disable-next-line no-param-reassign
      delete action.payload.location.state

      pageView(action.payload.location)
      break
    }

    case ENTITIES_USER_ADD: {
      const { user } = action

      trackReduxAction(action, user.toSegmentIdentity())

      break
    }

    default:
      break
  }

  next(action)
}

export default metrics
