import { Map } from 'immutable'
import { REHYDRATE } from 'redux-persist/constants'

import { TypedMap } from 'src/utils/typed-map'
import { LocalSettingsSetAction } from 'src/localSettings/actions'
import type { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import Organization from 'src/entities/organization/model'
import { Coordinates } from 'src/utils/coordinates'

import { SET, RESET_LOCAL_SETTINGS } from './constants'

export interface LocalSettingsMutable {
  currency?: CurrencyCode | null
  username?: string | null
  userId?: string | null
  organization?: string | null
  brand?: Organization | null
  previousLocation?: string | null
  expediteOnboarding?: boolean
  menuModalVisibility?: boolean
  filtersShown?: boolean
  searchLocation?: { location: string | false | null; coordinates: Coordinates | null } | null

  // legacy
  brandColor?: string | null
  deviceToken?: string
}

export type LocalSettingsState = TypedMap<LocalSettingsMutable>

const initialState = Map() as LocalSettingsState

export default (
  state = initialState,
  action:
    | LocalSettingsSetAction
    | { type: typeof REHYDRATE; payload: { localSettings: LocalSettingsState } }
    | { type: typeof RESET_LOCAL_SETTINGS }
): LocalSettingsState => {
  switch (action.type) {
    case SET:
      return state.set(action.key, action.value)

    case REHYDRATE:
      return action?.payload?.localSettings || state

    case RESET_LOCAL_SETTINGS:
      return initialState

    default:
      return state
  }
}
